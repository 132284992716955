import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding-bottom: 24px;
`;

const Text = styled.span``;

const Footer = () => (
  <Container>
    <Text>Þúfubarð 4 - 220 Hafnarfjörður - kt. 630919-0370</Text>
  </Container>
);

Footer.propTypes = {};

export default Footer;
