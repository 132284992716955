import React, { useState } from 'react';
import styled from 'styled-components';
import { FaMobileAlt, FaEnvelope } from 'react-icons/fa';
import Layout from '../components/layout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: #f0f4f8;
  /* font-family: "Roboto", sans-serif; */
`;

const TextWrapper = styled.div`
  grid-area: text;
  max-height: 80%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: 300;
  margin: 48px 0;
`;
const Heading = styled.div`
  margin: 0px;
  font-size: 48px;
  font-weight: 700;
  height: auto;
  text-align: center;

  @media screen and (max-width: 860px) {
    font-size: 36px;
  }
`;
const ContactInfo = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  grid-gap: 8px;
  width: auto;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  max-width: 70%;
  min-height: 400px;

  @media screen and (max-width: 860px) {
    max-width: 80%;
  }
`;

const ContactButton = styled.div`
  max-width: 176px;
  height: 48px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 400;
  background: ${({ primary }) => (primary ? '#334E68' : '#D9E2EC')};
  color: ${({ primary }) => (primary ? '#D9E2EC' : '#334E68')};
  cursor: pointer;

  &:hover {
    background: ${({ primary }) => (primary ? '#243B53' : '#BCCCDC')};
  }
`;

const TelLink = styled.a`
  text-decoration: none;
  color: unset;
`;

const Dropdown = styled.div`
  width: 105px;
  height: 25px;
`;

export default () => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <Layout>
      <Container>
        <Content>
          <TextWrapper>
            <Heading>Grund-Barð ehf.</Heading>
            {/* <Text>
              Við keyrum trukka og erum aldrei að sukka. Vanir menn og dömur, með öll réttindi og
              margra áratuga reynslu sín á milli. Okkar slagorð er: Ef flytja skal efni eða vöru yfir
              fjöru og skarð, hringið þá í Grund-Barð.
            </Text> */}
            <Text>
              Við bjóðum upp á efnisflutninga og getum tekið að okkur dráttabílaþjónustu ef draga
              þarf flatvagn eða body. Ökumenn okkar eru öllu vanir og með áratuga reynslu sín á
              milli.
            </Text>
            <ContactInfo>
              <ContactButton primary>
                <FaMobileAlt style={{ marginRight: 8 }} />
                <TelLink href="tel:+3548445270">844-5270</TelLink>
              </ContactButton>
              <ContactButton>
                <FaEnvelope style={{ marginRight: 8 }} />
                arni@grundbard.is
              </ContactButton>
              {/* <Dropdown>Hello</Dropdown> */}
            </ContactInfo>
          </TextWrapper>
          {/* <ImageWrapper>
            <Image src="/Volvo-min.png" />
          </ImageWrapper> */}
        </Content>
      </Container>
    </Layout>
  );
};
