import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Footer from './footer/Footer';

const styledTheme = {
  primaryColor: '#102a43',
  background: '#f0f4f8',
};

const GlobalStyle = createGlobalStyle`
  html, body {
    /* color: ${({ theme }) => theme && theme.primaryColor}; */
    /* background: ${({ theme }) => theme && theme.background}; */
    margin: 0;
    padding: 0;
    /* height: 100vh; */
    /* background: url("/BlueVolvo-min.jpg") no-repeat center center fixed;Here's the trick */
    background: linear-gradient(rgba(10,10,10,0.7), rgba(10,10,10,0.7)) , url("/BlueVolvo-min.jpg") center center;
    background-size: cover;
  }
  span, div, p {
    font-family: "Roboto", sans-serif;
    color: #FEFEFE;
  }
`;

export default ({ children }) => (
  <ThemeProvider theme={styledTheme}>
    <GlobalStyle />
    {children}
    <Footer />
  </ThemeProvider>
);
